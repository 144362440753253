import {POPUP_CONTAINER_ID, SNACKBAR_CONTAINER_ID} from '@constants/general';

function maybeAddContainer(id: string) {
    if (!document.getElementById(id)) {
        addContainer(id);
    }
}

function addContainer(id: string) {
    document.body.appendChild(makeElement('div', id));
}

function makeElement(tagName: string, id: string): HTMLElement {
    const element = document.createElement(tagName);
    element.setAttribute('id', id);
    return element;
}

maybeAddContainer(POPUP_CONTAINER_ID);
maybeAddContainer(SNACKBAR_CONTAINER_ID);
