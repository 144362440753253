import type {ComponentType, LazyExoticComponent, ReactElement} from 'react';
import {Suspense} from 'react';
import {render} from 'react-dom';

export default function renderComponent<T extends {} = {}>(
    selector: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: ComponentType<T> | LazyExoticComponent<any>,
    fallback?: ComponentType<T> | string,
) {
    const rootPoints: NodeListOf<HTMLDivElement> = document.querySelectorAll(selector);

    if (rootPoints.length > 0) {
        for (const element of rootPoints) {
            const props: {} = element.dataset.props ? (JSON.parse(element.dataset.props) as T) : {};
            const content = Array.from(element.children);
            delete element.dataset.props;
            element.classList.remove(getClassNameFromSelector(selector));

            render(
                <Suspense fallback={getFallback<T>(fallback, props as T)}>
                    <Component {...(props as T)}>{content}</Component>
                </Suspense>,
                element,
            );
        }
    }
}

function getClassNameFromSelector(selector: string): string {
    return selector.substring(1);
}

function getFallback<T extends {} = {}>(
    fallback: ComponentType<T> | string | undefined,
    props: T,
): ReactElement | null {
    if (typeof fallback === 'undefined') {
        return null;
    }

    if (typeof fallback === 'string') {
        return <div className={fallback} />;
    }

    const Component = fallback;
    return <Component {...props} />;
}
